import { createContext, FC, ReactNode, useState } from 'react'

export type Toast = {
  id: string
  title: string
  description: string
}

type ToastContextType = {
  create: (toast: Toast) => void
  remove: () => void
  toasts: Toast[]
}

export const ToastContext = createContext<ToastContextType>(
  {} as ToastContextType
)

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([])

  const create = (toast: Toast) =>
    setToasts((prevToasts) => [toast, ...prevToasts])

  const remove = () => {
    if (toasts !== null && toasts.length > 2) setToasts(toasts.slice(0, 3))
    return null
  }

  return (
    <ToastContext.Provider value={{ create: create, remove: remove, toasts }}>
      {children}
    </ToastContext.Provider>
  )
}
