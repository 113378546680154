import '@css/globals.css'
import '@css/blog.css'
import { ToastProvider } from '@lib/ToastProvider'
import type { AppProps } from 'next/app'
import { MenuProvider, MenuConfig } from 'kmenu'
import NextNProgress from 'nextjs-progressbar'
import localFont from '@next/font/local'

const display = localFont({ src: '../public/fonts/inter.woff2' })
const sans = localFont({ src: '../public/fonts/sohne.woff2' })
const mono = localFont({ src: '../public/fonts/fira_code.woff2' })

const Experiments = ({ Component, pageProps }: AppProps) => {
  const config: MenuConfig = {
    backdropColor: '#00000099',
    backgroundColor: '#161616',
    backdropBlur: 0.1,
    borderWidth: 1,
    borderColor: 'var(--grey6)',
    inputBorder: 'var(--grey6)',
    inputColor: 'var(--grey0)',
    barBackground: '#FFFFFF10',
    headingColor: 'var(--grey5)',
    commandInactive: 'var(--grey5)',
    commandActive: 'var(--grey0)',
    breadcrumbColor: 'var(--grey8)',
  }

  return (
    <MenuProvider
      config={config}
      dimensions={{ commandHeight: 52, sectionHeight: 35 }}
    >
      <NextNProgress color='#585858' options={{ showSpinner: false }} />
      <ToastProvider>
        <style jsx global>
          {`
            :root {
              --font-display: ${display.style.fontFamily}, var(--font-fallback);
              --font-sans: ${sans.style.fontFamily}, var(--font-fallback);
              --font-mono: ${mono.style.fontFamily}, monospace;
            }
          `}
        </style>
        <Component {...pageProps} />
      </ToastProvider>
    </MenuProvider>
  )
}

export default Experiments
